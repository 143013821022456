((window, factory) => {
	const modal = factory(window, window.document);
	window.modal = modal;
	if(typeof module == 'object' && module.exports){
		module.exports = modal;
	}
})(window, (window, document) => {

    HTMLElement.prototype.empty = function() {
        while(this.firstChild) {
            this.removeChild(this.firstChild);
        }
        return this;
    }

    const open = (modalContentHtml, hash) => {
        if (hash) {
            history.pushState('', document.title, `${window.location.pathname}#${hash}`);
        }
        const elModal = document.getElementById('modal');
        const elModalContent = document.getElementById('modal-content');
        const elModalClose = document.getElementById('modal-close');
        const elModalCurtainClose = document.getElementById('modal-curtain-close');
        if (elModalContent) {
            elModalContent.insertAdjacentHTML('afterbegin', modalContentHtml);
        }
        elModal.classList.add('show');
        document.body.classList.add('scroll-lock');
        [elModalClose, elModalCurtainClose].forEach(el => {
            el.addEventListener('click', () => {
                close();
            })
        });
    }

    const close = () => {
        const elModal = document.getElementById('modal');
        const elModalContent = document.getElementById('modal-content');
        elModal.classList.add('is-closing');
        history.pushState(null, null, ' ');
        setTimeout(() => {
            elModal.classList.remove('show');
            document.body.classList.remove('scroll-lock');
            elModalContent.empty();
            elModal.classList.remove('is-closing');
        }, 300);
    }

    const modal = {
        open: open,
        close: close
    }

    return modal;

});